import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faCoffee } from '@fortawesome/free-solid-svg-icons'
import React from 'react';

const View = (props) => {
    let params = useParams();
    const [ prevLDescription, setPrevLDescription ] = React.useState(undefined);
    const [ page, setPage ] = React.useState(0);
    const [ element, setElement] = React.useState(window.app.WORKS[params.category].content[params.element].gallery[page]);

    /*React.useEffect(() => {
        function handleKeyDown(e) {
            e.preventDefault();

            if(e.keyCode === 39){
                goToNextPage();
            }else if(e.keyCode === 37){
                goToPrevPage();
            }
        }
    
        document.addEventListener('keydown', handleKeyDown);
    
        // Don't forget to clean up
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
      }, []);*/

    React.useEffect(() => {
        setElement( window.app.WORKS[params.category].content[params.element].gallery[page] )
    }, [page])

    const goToPrevPage = () => {
        if(page > 0){
            if(page == 1){
                setPrevLDescription("");
            }else{
                setPrevLDescription(element.l_text);
            }
            
            setPage(page-1);
        }
    }

    const goToNextPage = () => {
        if( page+1 < window.app.WORKS[params.category].content[params.element].gallery.length  ){
            setPrevLDescription(element.l_text);
            setPage(page+1);
        }
    }

    const renderDesktopGallery = () => {
        return (
            <div id="gallery">
                <div id="image" style={{backgroundImage: "url('" + element.pic + "')"}}>
                    <div onClick={ () => goToPrevPage() } id="image-overlay-prev">
                        {
                            page > 0 &&
                            <FontAwesomeIcon icon={faAngleLeft} />
                        }
                    </div>
                    <div onClick={ () => goToNextPage() } id="image-overlay-next" className="r">
                        {
                            page+1 != window.app.WORKS[params.category].content[params.element].gallery.length &&
                            <FontAwesomeIcon icon={faAngleRight} />
                        }
                    </div>
                </div>
                
                <div id="gallery-navigation">
                    
                    { page+1 }
                    &nbsp;/&nbsp;
                    { window.app.WORKS[params.category].content[params.element].gallery.length }
                </div>

                <div id="gallery-description">
                    <div
                        className={ (prevLDescription === element.l_text ? "hidden" : "") }
                    >
                        { HTMLReactParser(element.l_text) }
                    </div>
                    <div>{ HTMLReactParser(element.r_text) }</div>
                </div>
            </div>
        )
    }

    const renderMobileGallery = () => {
        return (
            <div className="mobile" id="gallery">
                {
                    window.app.WORKS[params.category].content[params.element].gallery.map((element, index) => {
                        return (
                            <div key={"mg" + index}>
                                {
                                    (index === 0  
                                    || 
                                    window.app.WORKS[params.category].content[params.element].gallery[index-1].r_text !== element.r_text)
                                    &&
                                    <div className="r-description">
                                        { HTMLReactParser(element.r_text) }
                                    </div>
                                }
                                
                                <img src={element.pic} />

                                {
                                    (index === 0  
                                    || 
                                    (index > 0 && window.app.WORKS[params.category].content[params.element].gallery[index-1].l_text !== element.l_text))
                                    &&
                                    <div className="l-description">
                                        { HTMLReactParser(element.l_text) }
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    if( window.innerWidth > 900 ) {
        return renderDesktopGallery();
    }else{
        return renderMobileGallery();
    }
}

export default View;
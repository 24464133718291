import { Routes, Route } from 'react-router-dom';
import Works from './pages/Home';
import Contacts from './pages/Contacts';
import View from './pages/View';
import About from './pages/About';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div id="playground">
      <Navigation />

      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Works />} />
          
          <Route path="works" element={<Works />} >
            <Route path=":category" element={<Works />} />
          </Route>
          
          <Route path="view/:category/:element" element={<View />} />
          
          <Route path="contacts" element={<Contacts />} />

          <Route path="about" element={<About />} />
        </Routes>
      </ScrollToTop>

      <Footer />
    </div>
  )
}

export default App;

import { Link, useLocation } from 'react-router-dom';
import React from 'react';

const Navigation = (props) => {
    const location = useLocation();

    const getMenuSelection = () => {
        const menuItems = ['works', 'view', 'about', 'contacts', 'press'];
        let ret = "works";

        menuItems.forEach((e) => {
            if( window.location.href.includes(e) ) {
                ret = e;
            }
        })

        if(ret === 'view'){
            ret = 'works';
        }

        return ret;
    }

    const getSubmenuSelection = () => {
        const subMenuItems = ['in-progress', 'public', 'private', 'graphic'];
        let ret = "all";

        subMenuItems.forEach((e) => {
            if( window.location.href.includes(e) ) {
                ret = e;
            }
        })

        return ret;
    }

    const [ menuSelection, setMenuSelection ] = React.useState( undefined );
    const [ submenuSelection, setSubmenuSelection ] = React.useState(undefined);

    React.useEffect(() => {
        setMenuSelection( getMenuSelection() )
        setSubmenuSelection( getSubmenuSelection() );
    }, [location]);

    React.useEffect(() => {
        setSubmenuSelection( getSubmenuSelection() );
    }, [menuSelection]);

    return (
        <div id="header">
            <Link to="/">
                <img alt="Natale Avolio Logo" src="/logo_s1.png" id="logo" />
            </Link>

            <div id="menu">
                <div id="mainmenu">
                    <Link className={ menuSelection === 'works' ? 'selected' : '' } to="works">WORKS</Link> |
                    <Link className={ menuSelection === 'about' ? 'selected' : '' } to="about">ABOUT</Link> |
                    <Link className={ menuSelection === 'contacts' ? 'selected' : '' } to="contacts">CONTACTS</Link> |
                    <Link className={ menuSelection === 'press' ? 'selected' : '' } to="view/press/press">PRESS</Link>
                </div>
                {
                    menuSelection === "works" && 
                    <div id="submenu">
                        <Link className={ submenuSelection === 'all' ? 'selected' : '' } to="works">all</Link> |
                        <Link className={ submenuSelection === 'in-progress' ? 'selected' : '' } to="works/in-progress">in progress</Link> |
                        <Link className={ submenuSelection === 'public' ? 'selected' : '' } to="works/public">public</Link> |
                        <Link className={ submenuSelection === 'private' ? 'selected' : '' } to="works/private">private</Link> | 
                        <Link className={ submenuSelection === 'graphic' ? 'selected' : '' } to="works/graphic">graphic</Link> 
                    </div>
                }   
            </div>
        </div>
   );
}

export default Navigation;
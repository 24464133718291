import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Footer = (props) => {
    const location = useLocation();

    return (
        <div id="footer">
            {
                !location.pathname.includes("view/") && 
                <div style={{textAlign: "center"}}>
                    <img src="/logo_bottom.png" alt="Natale Avolio Logo" />
                </div>
            }
            

            <div style={{width: "100%", height: "1px", backgroundColor: "black", margin: "0.5em 0"}}></div>

            Copyright &copy; { new Date().getFullYear() } Natale Avolio. All Rights Reserved.
        </div>
   );
}

export default Footer;
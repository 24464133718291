const Contacts = (props) => {
    return (
        <div id="contacts">
            <div>
                <div>
                    <div>NAME</div>
                    <div><input autoComplete="false"/></div>
                </div>

                <div>
                    <div>EMAIL</div>
                    <div><input autoComplete="false"/></div>
                </div>
                
                <div>
                    <div>PHONE</div>
                    <div><input autoComplete="false"/></div>
                </div>

                <div class="textarea">
                    <div>MESSAGE</div>
                    <div><textarea /></div>
                </div>  

                <div style={{textAlign: 'center'}}>
                    <button>SEND</button>
                </div>
            </div>

            <div>
                <div>
                    <iframe width="100%" height="350" id="gmap_canvas" src="https://maps.google.com/maps?q=corigliano&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                </div>

                <div>
                    <div>
                    Natale Avolio architetto<br />
                    87064 Corigliano Rossano (CS)<br /> 
                    IT 
                    </div>

                    <div>
                        tel. +39 339 4879660<br />
                        nataleavolio@libero.it
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
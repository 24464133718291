import { useParams, Link } from "react-router-dom";
import React from 'react';

const Works = (props) => {
    const [ works, setWorks ] = React.useState([])
    let params = useParams();
    let currentCategory = "category" in params ? params.category : "all";

    React.useEffect(() => {
        if(currentCategory === "all"){
            let aggr = {};

            Object.keys(window.app.WORKS).map((k, index) => {
                if( !window.app.WORKS[k].hidden_category ){
                    aggr = {...aggr, ...window.app.WORKS[k].content}
                }
            })

            setWorks(aggr);
            return;
        }

        setWorks(window.app.WORKS[currentCategory].content);
    }, [currentCategory]);

    return (
        <div id="works-grid">
            {
                Object.keys(works).map((k, index) => {
                    const e = works[k];

                    return (
                        <Link key={'w' + index} to={"/view/" + e.category + "/" + k}>
                            <div
                                style={{backgroundImage: "url('" + e.preview + "')"}}
                                className="work-card">
                                
                                <div className="work-card-details">
                                    <div><b>{e.title}</b></div>
                                    <div>{e.description}</div>
                                </div>

                            </div>
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default Works;
import { Link } from "react-router-dom";

const About = (props) => {
    return (
        <div id="about">
            <div>
                <img src="/about_1.jpg" />
                <div>
                    {
                        <a target="_blank" title="Divisare" href="http://divisare.com/authors/888902598-natale-avolio">
                        <img src="/divisare.png" />
                        </a>
                    }

                    <a target="_blank" title="Archilovers" href="http://www.archilovers.com/natale-avolio/">
                    <img src="/al.jpg" />
                    </a>

                    <a target="_blank" title="LinkedIn" href="http://it.linkedin.com/pub/natale-avolio/65/a86/306">
                    <img src="/lin.png" />
                    </a>

                    <a target="_blank" title="Flickr" href="http://www.flickr.com/photos/natale_avolio_arch/">
                    <img src="/flcr.png" />
                    </a>
                </div>
            </div>

            <div id="bio">
                Laureato in Architettura presso l'Università degli Studi di Firenze nel 1992, ancora studente sviluppa approfondimenti sul design e sulla composizione architettonica. Ne nascono esperienze e confronti che lo vedono incluso, a partire dall’ ’87, nell’ “Archivio Giovani Artisti Fiorentini” (sezione Architettura e Design) a cura dell’Assessorato alla Cultura del Comune di Firenze e nell'annuario Art Diary alla voce designer della città di Firenze (Giancarlo Politi Editore, Milano 1989). In questo contesto, selezionato e presente in catalogo, partecipa con una sua realizzazione ad una collettiva presso il Salone Brunelleschiano dell’Ospedale degli Innocenti in Firenze.
                <br /><br />
                Neolaureato, offre la sua collaborazione in ambito disciplinare prima a Firenze e poi a Corigliano, lavorando sulle destinazioni d’uso del Castello Ducale (Ala del Museo Informatizzato).
                Redige una ricerca su incarico del Laboratorio Interfacoltà di Scienze della Comunicazione dell’Università degli Studi della Calabria, dal titolo: "Gli scavi di Sibari, ipotesi e risultati descrittivi attraverso l’applicazione della computer-graphic".
                Vince il primo premio per il concorso di idee "Progetto di fattibilità di un Percorso Ostensorio" del Museo Nazionale di Antropologia ed Etnologia - Palazzo Nonfinito, Firenze.
                <br /><br />
                Dal 1995 opera come libero professionista a Corigliano Calabro. 
            </div>
        </div>
    );
}

export default About;